import axios from "axios";

const httpRequest = axios.create({
    baseURL: "https://api.vietqr.io/v2/banks",
});

export const getAll = async () => {
    try {
        const res = await httpRequest.get(``, {});
        return res.data;
    } catch (err) {}
};
